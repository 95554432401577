<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">{{$t('message.containers')}}</div>
                <div class="filter_filial">
                    <select-from-filial
                        :size="'medium'"
                        :select_only="true"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.from_where.title"
                        v-model="filterForm.from_filial_id"
                    >
                    </select-from-filial>
                    <select-to-filial
                        :size="'medium'"
                        :show_all="1"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="columns.to_where.title"
                        v-model="filterForm.to_filial_id"
                    >
                    </select-to-filial>
                </div>
            </div>
          </el-col>

          <el-col :xs="24" :sm="24" :md="24" :lg="6" :xl="6" class="flex-style text-right" :class="mode ? 'button__settingsday' : 'button__settingsnight'">
            <el-button size="small" class="btu-color" @click="sendToFlight = true" v-if="permissions.some(per => per.slug == 'containers.sendReadyContainersToFlight')">
              {{ $t('message.send_from_warehouse') }}
            </el-button>
            <el-button size="small" class="btu-color" @click="drawerCreate = true" v-if="permissions.some(per => per.slug == 'containers.create')">
              {{ $t('message.create') }}
            </el-button>
           
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

        <div class="card-table-header table-crm-smart custom__scroll">
            <table 
            class="table-my-code table-bordered"
            :class="mode ? 'table__myday' : 'table__mynight'"
            v-loading="loadingData"
            >
            <thead>
                <tr>
                    <th class="w50p" v-if="columns.id.show">
                        {{ columns.id.title }}
                    </th>

                    <th v-if="columns.container_type_id.show">
                        {{ columns.container_type_id.title }}
                    </th>

                    <th v-if="columns.barcode.show">
                        {{ columns.barcode.title }}
                    </th>

                    <th v-if="columns.airway_bill_number.show">
                        {{ columns.airway_bill_number.title }}
                    </th>

                    <th v-if="columns.weight.show">
                        {{ columns.weight.title}}
                    </th>

                    <th v-if="columns.from_where.show">
                        {{ columns.from_where.title }}
                    </th>

                    <th v-if="columns.to_where.show">
                        {{ columns.to_where.title }}
                    </th>
                    
                    <th v-if="columns.status.show">
                        {{ columns.status.title }}
                    </th>
                    
                    <th v-if="columns.created_at.show">
                        {{ columns.created_at.title }}
                    </th>

                    <th v-if="columns.updated_at.show">
                        {{ columns.updated_at.title }}
                    </th>

                    <th v-if="columns.settings.show">
                        {{ columns.settings.title }}
                    </th>
                </tr>

                <tr class="filter_sorche">
                    <th v-if="columns.id.show">
                        <el-input
                            clearable
                            size="mini"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            v-model="filterForm.id"
                            :placeholder="columns.id.title"
                            class="id_input"
                        ></el-input>
                    </th>

                    <th v-if="columns.container_type_id.show">
                        <select-container-type
                            :placeholder="columns.container_type_id.title"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            v-model="filterForm.container_type_id"
                        ></select-container-type>
                    </th>

                    <th v-if="columns.barcode.show">
                        <crm-input
                            :placeholder="columns.barcode.title"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            v-model="filterForm.barcode"
                        ></crm-input>
                    </th>

                    <th v-if="columns.airway_bill_number.show">
                        <crm-input
                            :placeholder="columns.airway_bill_number.title"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            v-model="filterForm.airway_bill_number"
                        ></crm-input>
                    </th>

                    <th v-if="columns.weight.show">
                        <crm-input
                            :placeholder="columns.weight.title"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            v-model="filterForm.weight"
                            disabled
                        ></crm-input>
                    </th>

                    <th v-if="columns.from_where.show">
                        <select-from-filial
                            :size="'medium'"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            :placeholder="columns.from_where.title"
                            v-model="filterForm.from_filial_id"
                        >
                        </select-from-filial>
                    </th>

                     <th v-if="columns.to_where.show">
                        <select-to-filial
                            :size="'medium'"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            :placeholder="columns.to_where.title"
                            v-model="filterForm.to_filial_id"
                        >
                        </select-to-filial>
                    </th>

                    <th v-if="columns.status.show">
                        <crm-input
                            :placeholder="columns.status.title"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            v-model="filterForm.status"
                        ></crm-input>
                    </th>

                    <th v-if="columns.created_at.show">
                        <crm-date-picker
                            :placeholder="columns.created_at.title"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            v-model="filterForm.created_at"
                        ></crm-date-picker>
                    </th>

                    <th v-if="columns.updated_at.show">
                        <crm-date-picker
                            :placeholder="columns.updated_at.title"
                            :class="mode ? 'filter__day' : 'filter__night'"
                            v-model="filterForm.updated_at"
                        ></crm-date-picker>
                    </th>

                    <th
                        class="settinW"
                        v-if="columns.settings.show"
                    ></th>
                </tr>
            </thead>

            <transition-group name="flip-list" tag="tbody">
                <tr v-for="container in list" :key="container.id" class="cursor-pointer">

                    <td v-if="columns.id.show">
                        {{ container.id }}
                    </td>

                    <td v-if="columns.container_type_id.show">
                        {{ container.containerType ? container.containerType.name : '' }}
                    </td>

                    <td v-if="columns.barcode.show">
                        {{ container.barcode }}
                    </td>

                    <td v-if="columns.airway_bill_number.show">
                        {{ container.airway_bill_number ? container.airway_bill_number : '-' }}
                    </td>

                    <td v-if="columns.weight.show">
                        {{ container.all_weigth | formatNumber(1)}}
                    </td>

                    <td v-if="columns.from_where.show">
                        {{ container.from_filial ? container.from_filial.name : '' }}
                    </td>

                    <td v-if="columns.to_where.show">
                        {{ container.to_filial ? container.to_filial.name : '' }}
                    </td>

                    <td v-if="columns.status.show">
                        <span v-if="container.in_filial && !container.airway_bill_number" style="color:red">
                            <el-button type="danger" plain size="mini">  {{ $t('message.in_sending_filial') }}</el-button>
                        </span>

                        <span v-if="!container.in_filial && !container.airway_bill_number" style="color:blue">
                            <el-button type="primary" plain size="mini"> {{ $t('message.in_airport') }}</el-button>
                        </span>

                        <span v-if="!container.in_filial && container.airway_bill_number" style="color:yellow">
                            <el-button type="warning" plain size="mini">{{ $t('message.on_flight') }}</el-button>
                        </span>

                        <span v-if="container.in_filial && container.airway_bill_number" style="color:green">
                            <el-button type="success" plain size="mini">{{ $t('message.in_recieving_filial') }}</el-button>
                        </span>
                    </td>
                
                    <td v-if="columns.created_at.show">
                        {{ container.created_at }}
                    </td>

                    <td v-if="columns.updated_at.show">
                        {{ container.updated_at }}
                    </td>

                    <td>
                        <div class="flight__edit">
                            <a class="ml-1" @click="editAirwayBill(container)" v-if="permissions.some(per => per.slug == 'containers.setAirwayBillNumber')">
                                <i class="fa-solid fa-plane-departure"></i>
                            </a>
                            <a class="ml-2" @click="edit(container)" v-if="permissions.some(per => per.slug == 'containers.edit')">
                                <i class="fa-regular fa-pen-to-square"></i>
                            </a>
                            <a class="ml-2" @click="printBarCode(container)">
                                <i class="fa fa-print"></i>
                            </a>
                            <a class="ml-2" @click="destroyMethod(container)" v-if="permissions.some(per => per.slug == 'containers.delete') && container.in_filial && !container.airway_bill_number">
                                <i class="fa fa-trash"></i>
                            </a>
                        </div>
                    </td>
                </tr>
            </transition-group>
            </table>
            
            <el-dialog class="dialog__modal" :title="$t('message.airwaybill_input')" :visible.sync="dialogItemsVisible" width="30%" @closed="updateContainer = {}" >
                <div v-loading="loadingUpdate">
                    <el-input
                        clearable
                        size="medium"
                        class="mt-2"
                        v-model="updateContainer.airway_bill_number"
                        :class="mode ? 'filter__day' : 'filter__night'"
                        :placeholder="''"
                    ></el-input>
                    <div class="cargo__right mt-5">
                        <el-button @click="setContainerAirwayBill()" type="success" plain v-if="permissions.some(per => per.slug == 'containers.setAirwayBillNumber')"> 
                            {{ $t('message.save') }} 
                        </el-button>
                    </div>
                </div>
                

            </el-dialog>
            <div class="my___pagination">
                <crm-pagination
                    :class="mode ? 'pagination__day' : 'pagination__night'"
                    @c-change="updatePagination"
                    :pagination="pagination"
                ></crm-pagination>
            </div>
        </div>

        <div class="app-modal app-modal__full modal-color-bg">
            <el-drawer
                :with-header="false" 
                class="bg-se"
                size="100%" 
                :visible.sync="drawerCreate"
                ref="drawerCreate"
                @opened="drawerOpened('drawerCreateChild')"
                @closed="drawerClosed('drawerCreateChild')"
            >
                <crm-create
                    @openUpdate="edit"
                    ref="drawerCreateChild"
                    drawer="drawerCreate"
                    > 
                </crm-create>
            </el-drawer>

            <el-drawer
                :with-header="false"
                class="bg-se"
                :visible.sync="drawerUpdate"
                size="100%" 
                ref="drawerUpdate"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
            >
                <crm-update
                    :selectedItem="selectedItem"
                    ref="drawerUpdateChild"
                    drawer="drawerUpdate"
                ></crm-update>
            </el-drawer>
        
            <el-drawer
                :with-header="false" 
                class="bg-se"  
                :visible.sync="sendToFlight" 
                ref="drawerSendToFlight" 
                @opened="drawerOpened('drawerSendToFlightChild')"
                @closed="drawerClosed('drawerSendToFlightChild')"
                size="70%" >
                
                <crm-send
                    ref="drawerSendToFlightChild"
                    drawer="drawerSendToFlight">
                </crm-send>
            </el-drawer>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import CrmSend from "./components/crm-send";
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import SelectContainerType from '../../components/inventory/select-container-type';
import selectFromFilial from "@/components/selects/select-from-filial";
import selectToFilial from "@/components/selects/select-to-filial";

export default {
    name: "costTransactions",
    mixins: [list],
    components: {
        Pagination,
        CrmCreate,
        CrmSend,
        CrmUpdate,
        SelectContainerType,
        selectFromFilial,
        selectToFilial,
    },

    data() {
        return {
            createModal: false,
            dialogItemsVisible: false,
            loadingUpdate: false,
            sendToFlight: false,
            updateContainer: {},
            activeType: "warehouse",
        };
    },
    computed: {
        ...mapGetters({
            list: "containers/list",
            permissions: "auth/permissions",
            columns: "containers/columns",
            pagination: "containers/pagination",            
            filter: "containers/filter",
            sort: "containers/sort",
            mode: "MODE"
        }),
    },
    methods: {
        ...mapActions({
            updateList: "containers/index",
            setPagination: "containers/setPagination",
            updateSort: "containers/updateSort",
            updateFilter: "containers/updateFilter",
            updateColumn: "containers/updateColumn",
            updatePagination: "containers/updatePagination",
            empty: "containers/empty",
            delete: "containers/destroy",
            refreshData: "containers/refreshData",
            printContainerBarcode: "containers/printContainerBarcode",
            setAirwayBillNumber: "containers/setAirwayBillNumber", 
        }),
        editAirwayBill(container){
            if(!container.in_filial){
                this.dialogItemsVisible = true
                this.updateContainer = JSON.parse(JSON.stringify(container));
            }
        },
        setContainerAirwayBill() {
            this.loadingUpdate = true;
            this.setAirwayBillNumber(this.updateContainer)
                .then(res => {
                    this.dialogItemsVisible = false
                    this.loadingUpdate = false;
                    this.fetchData();
                }).catch(err => {
                    this.loadingUpdate = false;
                });
        },
        destroyMethod(container) {
            this.$confirm(
                this.$t('message.do_you_really_want_to_do_this'),
                this.$t('message.warning'),
                {
                confirmButtonText: this.$t('message.yes'),
                cancelButtonText: this.$t('message.no'),
                type: "warning",
                }
            )
                .then(() => {
                    this.destroy(container);
                })
                .catch(() => {
                    this.$message({
                        type: "warning",
                        message: this.$t('message.deleting_product_canceled'),
                    });
                });
        },
        handleClick(type) {
            this.activeType = type;
            if (this.genderstatistic) {
                this.changeStatistics();
            }
        },
        printBarCode(container) {
            let payload = {container_id: container.id, barcode: container.barcode}
            this.printContainerBarcode(payload)
            .then(res => {
                const WinPrint = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status=0");
                WinPrint.document.write(res.data);
                WinPrint.document.close();
                WinPrint.focus();
                setTimeout(() => {
                    WinPrint.print();
                    WinPrint.close();
                }, 1000);
            })
            .catch(err => {
                this.$alert(err);
            });
        },
    },
    
};
</script>
<style>
    .space__between {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .space__between span {
        cursor: pointer;
        font-size: 18px;
    }
    .filter_filial{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 500px;
    height: 20px;
}
</style>
